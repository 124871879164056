<script lang="ts">
import type { IconClass } from '#core/types'
import type { sizes } from '../element/UiTooltip.vue'

export type LabelAlign = 'left' | 'center' | 'right'

export interface LabelProps {
  text?: string
  tooltip?: string
  icon?: IconClass
  tooltipSize?: keyof typeof sizes
  align?: LabelAlign
  for?: string
}
</script>

<script setup lang="ts">
const props = defineProps<LabelProps>()

const alignment = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end',
}
</script>

<template>
  <component
    :is="props.for ? 'label' : 'div'"
    data-ui="UiLabel"
    class="flex items-center gap-1"
    :class="align ? alignment[align] : undefined"
    :for="props.for"
  >
    <slot>{{ text }}</slot>
    <UiTooltip v-if="props.tooltip" :text="tooltip" :size="tooltipSize" />
    <UiTooltip v-else-if="$slots.tooltip" :size="tooltipSize">
      <template #text>
        <slot name="tooltip">
          {{ $slots.tooltip }}
        </slot>
      </template>
    </UiTooltip>
  </component>
</template>
